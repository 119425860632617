import configureStore from "../redux/store";

let result;

export const getStoreInstance = () => {
    if (!result) {
        result = configureStore();
    }
    return result;
};
