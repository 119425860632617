import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
    setupExternalServices,
    setupExternalServicesWithUser,
} from "../AppUtils";
import { initRunDependenciesIfAllowed } from "../externaldependenciesoptout/runDependenciesIfAllowed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountryModal from "../../components/header/CountryModal";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "../../components/ScrollToTop";
import { updateSiteLang } from "../../utils/SiteUtils";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const AppLayout = ({ children }) => {
    const user = useSelector((state) => state.auth);
    const loading = useSelector((state) => state.loading);

    const retrieveLocalTimezone = () => {
        let timeZone = 'N/A';
        if (typeof Intl !== 'undefined' && typeof Intl.DateTimeFormat === 'function') {
            timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        return timeZone;
    }

    const initRecordOptOut = ({ localTimeZone }) => async ({ clientId, label }) => {
        await window.fetch && window.fetch(`${process.env.REACT_APP_API_URL}/register-ping?label=${label}&clientId=${clientId}&tz=${localTimeZone}`, {
            method: 'GET'
        })
    }

    const localTimeZone = retrieveLocalTimezone();
    const runDependenciesIfAllowed = initRunDependenciesIfAllowed({
        localStorage: window.localStorage,
        locationHref: window.location.href,
        recordOptOut: initRecordOptOut({ localTimeZone })
    });

    useEffect(() => {
        updateSiteLang();
        runDependenciesIfAllowed('external-services', () => {
            setupExternalServices();
        });
    }, []);

    useEffect(() => {
        runDependenciesIfAllowed('external-services-with-user', () => {
            setupExternalServicesWithUser(user);
        });
    }, [user]);

    return (
        <div className="PLT">
            <style>{`
                body {
                    background: #effaff !important;
                }
            `}</style>
            <CountryModal />
            <div className={"Content"}>
                <ToastContainer position="top-center" hideProgressBar />
                <ScrollToTop>
                    { children }
                </ScrollToTop>
            </div>
            {loading.status && (
                <div className="loading">
                    <FontAwesomeIcon spin style={{ fontSize: "30px" }} icon={faSpinner} />
                </div>
            )}
        </div>
    );
};

export default AppLayout;
