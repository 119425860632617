import moment from "moment";
import { toast } from "react-toastify";
import internalApi from "../../config/internalApi";
import { FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY } from "../../constants";
import { logAmplitudeEvent } from "../../utils/log-amplitude-event";
import { createAction } from "../utils";
import { sendToGTM } from "./sendToGTM";

const UPDATE_SEARCH = "plt-web/search/UPDATE_SEARCH";
const UPDATE_SEARCH_LOADING = "plt-web/search/UPDATE_SEARCH_LOADING";
const UPDATE_SEARCH_FLIGHTS = "plt-web/search/UPDATE_SEARCH_FLIGHTS";
const SELECT_HOTEL = "plt-web/search/SELECT_HOTEL";
const UPDATE_SEARCH_RESULTS = "plt-web/search/UPDATE_SEARCH_RESULTS";
const LOADING_SEARCH_RESULTS = "plt-web/search/LOADING_SEARCH_RESULTS";
const SET_CARRIERS_FILTER = "plt-web/search/SET_CARRIERS_FILTER";
const CLEAR_CARRIERS_FILTER = "plt-web/search/CLEAR_CARRIERS_FILTER";
const EMPTY_SEARCH_RESULTS = "plt-web/search/EMPTY_SEARCH_RESULTS";
const SET_CARRIERS_STOP_FILTER = "plt-web/search/SET_CARRIERS_STOP_FILTER";
const SET_CARRIERS_ARRIVAL_FILTER = "plt-web/search/SET_CARRIERS_ARRIVAL_FILTER";
const SET_CARRIERS_DEPARTURE_FILTER = "plt-web/search/SET_CARRIERS_DEPARTURE_FILTER";

const initialState = {
    query: {
        flights: {
            origin: null,
            destination: null,
            type: "return", // return or one-way
            departureDate: moment().add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, "days").format("YYYY-MM-DD"),
            returnDate: moment()
                .add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY + 14, "days")
                .format("YYYY-MM-DD"),
            adults: 1,
            children: 0,
            infants: 0,
            cabinClass: "Y",
            multiCityLegs: [
                {
                    origin: null,
                    destination: null,
                    departureDate: moment().add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, "days").format("YYYY-MM-DD")
                },
                {
                    origin: null,
                    destination: null,
                    departureDate: moment().add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, "days").format("YYYY-MM-DD")
                }
            ]
        },
        hotels: {
            destination: null,
            checkInDate: moment().add(180, "days").format("YYYY-MM-DD"),
            checkOutDate: moment().add(181, "days").format("YYYY-MM-DD"),
            adults: 2,
            childAges: [],
            rooms: 1
        }
    },
    results: {
        data: []
    },
    activeTab: 1, // 1 = flights, 2 = stays
    loading: false
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case UPDATE_SEARCH: {
            if (action.productType === "hotel") {
                return {
                    ...state,
                    query: {
                        ...state.query,
                        hotels: {
                            ...state.query.hotels,
                            ...action.data
                        }
                    },
                    activeTab: 2
                };
            }
            return {
                ...state,
                query: {
                    ...state.query,
                    flights: action.data
                },
                activeTab: 1
            };
        }
        case LOADING_SEARCH_RESULTS: {
            return {
                ...state,
                results: {
                    data: []
                },
                loading: true
            };
        }
        case EMPTY_SEARCH_RESULTS: {
            return {
                ...state,
                results: {
                    data: []
                }
            };
        }
        case SELECT_HOTEL: {
            return {
                ...state,
                results: {
                    ...state.results,
                    selectedHotel: {
                        hotelId: action.data
                    }
                }
            };
        }
        case UPDATE_SEARCH_LOADING: {
            return {
                ...state,
                loading: true
            };
        }
        case UPDATE_SEARCH_FLIGHTS: {
            if (action.payload.data === undefined) {
                return {
                    ...state,
                    results: {
                        data: [],
                        errors: { message: "No results" }
                    },
                    loading: false
                };
            }
            return {
                ...state,
                results: {
                    data: action.payload.data,
                    type: "flights",
                    carriers: action.payload.dictionaries ? action.payload.dictionaries.carriers : {},
                    carriersVisibilityFilter: action.payload.carriersVisibilityFilter,
                    hasAirlineCombinations: action.payload.hasAirlineCombinations,
                    carrierStopFilter: action.payload.carrierStopFilter,
                    carrierDepartureFilter: action.payload.carrierDepartureFilter,
                    carrierArrivalFilter: action.payload.carrierArrivalFilter
                },
                loading: false
            };
        }
        case UPDATE_SEARCH_RESULTS: {
            return {
                ...state,
                results: {
                    type: "hotels",
                    data: action.payload.data
                },
                loading: false
            };
        }
        case SET_CARRIERS_FILTER: {
            return {
                ...state,
                results: {
                    ...state.results,
                    carriersVisibilityFilter: action.payload
                }
            };
        }
        case CLEAR_CARRIERS_FILTER: {
            return {
                ...state,
                results: {
                    ...state.results,
                    carriersVisibilityFilter: [],
                    carrierArrivalFilter: [],
                    carrierDepartureFilter: [],
                    carrierStopFilter: []
                }
            };
        }
        case SET_CARRIERS_STOP_FILTER: {
            return {
                ...state,
                results: {
                    ...state.results,
                    carrierStopFilter: action.payload
                }
            };
        }
        case SET_CARRIERS_DEPARTURE_FILTER: {
            return {
                ...state,
                results: {
                    ...state.results,
                    carrierDepartureFilter: action.payload
                }
            };
        }
        case SET_CARRIERS_ARRIVAL_FILTER: {
            return {
                ...state,
                results: {
                    ...state.results,
                    carrierArrivalFilter: action.payload
                }
            };
        }
        default:
            return state;
    }
}

export const updateSearch = (data, productType = null) => ({
    type: UPDATE_SEARCH,
    data,
    productType
});

export const selectHotel = (data = {}) => ({
    type: SELECT_HOTEL,
    data
});

export const updateSearchLoading = () => ({
    type: LOADING_SEARCH_RESULTS
});

export const emptySearchResults = (data = {}) => ({
    type: EMPTY_SEARCH_RESULTS
});

const toFightOffersRequestBody = ({ searchQuery }) => {
    // build the paramaters for flight search
    let params = {
        adults: searchQuery.adults,
        children: searchQuery.children,
        infants: searchQuery.infants,
        currencyCode: searchQuery.currencyCode,
        cabinClass: searchQuery.cabinClass,
        limit: 200,
        usingCredit: searchQuery.c === "t" ? true : undefined,
        includedAirlineCodes: searchQuery.includedAirlineCodes || undefined,
        source: "sabre"
    };

    const { multiCityLegs } = searchQuery;
    const multiCityQueries = [];

    if (searchQuery.type === "multi-city") {
        multiCityLegs.forEach((leg) => {
            multiCityQueries.push({
                origin: leg.origin.id,
                destination: leg.destination.id,
                departureDate: moment(leg.departureDate).format("YYYY-MM-DD")
            });
        });
        params = {
            ...params,
            flights: multiCityQueries
        };
    } else {
        let returnFlight;
        params = {
            ...params,
            flights: [
                {
                    origin: searchQuery.origin.id,
                    destination: searchQuery.destination.id,
                    departureDate: searchQuery.departureDate
                }
            ]
        };
        if (searchQuery.returnDate) {
            params.flights.push({
                origin: searchQuery.destination.id,
                destination: searchQuery.origin.id,
                departureDate: searchQuery.returnDate
            });
        }
    }

    return params;
}

export const updateSearchFlights = (searchQuery, saveState, auth) => (dispatch) => {
    // cast ints
    searchQuery.adults = searchQuery.adults ? parseInt(searchQuery.adults) : 1;
    searchQuery.children = searchQuery.children ? parseInt(searchQuery.children) : 0;
    searchQuery.infants = searchQuery.infants ? parseInt(searchQuery.infants) : 0;

    dispatch(updateSearch(searchQuery));
    dispatch(updateSearchLoading());

    window.dataLayer = window.dataLayer || []; // todo: do we need to initialize it every time?
    sendToGTM({ searchQuery, dataLayer: window.dataLayer });

    internalApi
        .post("/v2/flight-offers", toFightOffersRequestBody({ searchQuery }), { "x-access-token": auth ? auth.token : undefined })
        .then((response) => {
            const flights = response.data;
            const carriers = [];
            let hasAirlineCombinations = false;
            flights.forEach((flight) => {
                if (flight.carriers.length === 1) {
                    carriers.push(flight.carriers[0]);
                } else if (flight.carriers.length > 1) {
                    hasAirlineCombinations = true;
                }
            });
            const updatedCarriersArray = [...new Set(carriers)];
            const updatedCarriersDictionary = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const key of updatedCarriersArray) {
                updatedCarriersDictionary[key] = response.dictionaries.carriers[key];
            }

            if (hasAirlineCombinations) {
                updatedCarriersArray.push("COMBINATIONS");
            }

            response.dictionaries.carriers = updatedCarriersDictionary;
            response.carriersVisibilityFilter = [];
            response.carrierStopFilter = [];
            response.carrierDepartureFilter = [];
            response.carrierArrivalFilter = [];
            response.hasAirlineCombinations = hasAirlineCombinations;
            dispatch(createAction(UPDATE_SEARCH_FLIGHTS, response));
        })
        .catch((err) => {
            toast.error(err.response?.data?.error?.message || "An error occured.");
            dispatch(createAction(UPDATE_SEARCH_FLIGHTS, []));
        });
};

export const updateSearchResults = (query) => (dispatch) => {
    dispatch(createAction(LOADING_SEARCH_RESULTS));

    // push search event to GTM dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "hotelBookingSearchStarted",
        searchData: query
    });

    // build params
    const params = {
        ...query,
        roomQuantity: query.rooms ? parseInt(query.rooms) : 1,
        adults: query.adults ? parseInt(query.adults) : 2,
        rooms: undefined,
        includeClosed: true,
        bestRateOnly: false,
        childAges: query.childAges.toString()
        // paymentPolicy: 'GUARANTEE',
        // boardType: 'ROOM_ONLY'
    };

    // validate everything is here
    const requiredParams = ["destination", "checkInDate", "checkOutDate", "adults", "roomQuantity"];

    let validRequest = true;

    requiredParams.forEach((field) => {
        if (!params[field]) {
            validRequest = false;
        }
    });

    if (!validRequest) {
        dispatch(createAction(UPDATE_SEARCH_RESULTS, { data: [] }));
    } else {
        dispatch(updateSearch(query, "hotel"));
        internalApi
            .get("/v2/hotel-offers", params)
            .then((response) => {
                if (response.errors) {
                    dispatch(createAction(UPDATE_SEARCH_RESULTS, { data: [] }));
                } else {
                    dispatch(createAction(UPDATE_SEARCH_RESULTS, response));
                }
            })
            .catch((err) => {
                toast.error(err.response.data ? err.response.data.message : "An error occured");
                dispatch(createAction(UPDATE_SEARCH_RESULTS, { data: [] }));
            });
    }
};

export const updateCarriersFilter =
    (data = []) =>
    (dispatch) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "bookingFilterAppliedAirlines",
            selectedFilters: data
        });

        dispatch(createAction(SET_CARRIERS_FILTER, data));
    };

export const clearCarriersFilter =
    (data = []) =>
    (dispatch) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "bookingFilterAppliedAirlines",
            selectedFilters: data
        });

        dispatch(createAction(CLEAR_CARRIERS_FILTER, data));
    };

const carrierStopFilter = {
    0: "Non stop",
    1: "1 Stop",
    2: "2+ Stop"
};
export const updateCarriersStopFilter =
    (data = []) =>
    (dispatch) => {
        logAmplitudeEvent("stopsFilterApplied", {
            data: data.map((item) => (Number(item) > 1 ? "2+ Stop" : carrierStopFilter[item]))
        });
        dispatch(createAction(SET_CARRIERS_STOP_FILTER, data));
    };

const carrierArrivalFilter = {
    1: "Early Morning",
    2: "Morning",
    3: "Afternoon",
    4: "Evening"
};
export const updateCarriersArrivalFilter =
    (data = []) =>
    (dispatch) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "bookingFilterArrivalAppliedAirlines",
            selectedFilters: data
        });
        logAmplitudeEvent("returnTimeFilterApplied", { data: data.map((item) => carrierArrivalFilter[item]) });
        dispatch(createAction(SET_CARRIERS_ARRIVAL_FILTER, data));
    };

const carrierDepartureFilter = {
    1: "Morning",
    2: "Afternoon",
    3: "Evening"
};

export const updateCarriersDepartureFilter =
    (data = []) =>
    (dispatch) => {
        logAmplitudeEvent("departureTimeFilterApplied", { data: data.map((item) => carrierDepartureFilter[item]) });
        dispatch(createAction(SET_CARRIERS_DEPARTURE_FILTER, data));
    };
