import moment from "moment-timezone";

const toGTMSearchData = ({ searchQuery }) => {
    const formattedDepartureDate = moment.tz(searchQuery.departureDate, "YYYY-MM-DD", "UTC");

    let formattedSearchQuery = {
        ...searchQuery,
        originIataCode:
            searchQuery.type !== "multi-city" ? searchQuery.origin.id : searchQuery.multiCityLegs[0].origin.id,
        originAirportName:
            searchQuery.type !== "multi-city" ? searchQuery.origin.name : searchQuery.multiCityLegs[0].origin.name,
        originCityName:
            searchQuery.type !== "multi-city" ? searchQuery.origin.city : searchQuery.multiCityLegs[0].origin.city,
        originCountry:
            searchQuery.type !== "multi-city" ? searchQuery.origin.country : searchQuery.multiCityLegs[0].origin.country,
        departureDayOfYear: formattedDepartureDate.dayOfYear(),
        departureWeekOfYear: formattedDepartureDate.week(),
        departureMonth: formattedDepartureDate.month() + 1,
        departureYear: formattedDepartureDate.year(),
        departureEpoch: formattedDepartureDate.unix(),
        isFamily: !!(searchQuery.children > 0 || searchQuery.infants > 0),
        destinationIataCode:
            searchQuery.type !== "multi-city"
                ? searchQuery.destination.id
                : searchQuery.multiCityLegs[0].destination.id,
        destinationAirportName:
            searchQuery.type !== "multi-city"
                ? searchQuery.destination.name
                : searchQuery.multiCityLegs[0].destination.name,
        destinationCityName:
            searchQuery.type !== "multi-city"
                ? searchQuery.destination.city
                : searchQuery.multiCityLegs[0].destination.city,
        destinationCountry:
            searchQuery.type !== "multi-city"
                ? searchQuery.destination.country
                : searchQuery.multiCityLegs[0].destination.country,
    };

    const formattedReturnDate = moment.tz(searchQuery.returnDate, "YYYY-MM-DD", "UTC");
    if (searchQuery.type !== "one-way") {
        formattedSearchQuery = {
            ...formattedSearchQuery,
            returnDayOfYear: formattedReturnDate.dayOfYear(),
            returnWeekOfYear: formattedReturnDate.week(),
            returnMonth: formattedReturnDate.month() + 1,
            returnYear: formattedReturnDate.year(),
            returnEpoch: formattedReturnDate.unix()
        };
    }
    return formattedSearchQuery;
};

export function sendToGTM({ searchQuery, dataLayer = [] }) {
    const formattedSearchQuery = toGTMSearchData({ searchQuery });

    dataLayer.push({
        event: "bookingSearchStarted",
        searchData: {
            ...formattedSearchQuery,
            tripLength:
                searchQuery.type === "one-way"
                    ? -1
                    : moment(searchQuery.returnDate, "YYYY-MM-DD").diff(moment(searchQuery.departureDate, "YYYY-MM-DD"), "days")
        }
    });
}