import { getStoreInstance } from '../store/ConfigureStore';
import axios from './restClient';
import amplitude from "amplitude-js";

class InternalApi {
    static formatUrl = (url) => `${url}`

    static headers = () => {
      const { store } = getStoreInstance();
      const { token } = store.getState().auth;
      const timezoneOffset = new Date().getTimezoneOffset() * -1;
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Timezone-Offset': timezoneOffset,
        'X-Amplitude-User-Id': amplitude.getInstance().getUserId(),
        'X-Amplitude-Device-Id': amplitude.getInstance().getDeviceId(),
      };

      if (token.length > 0) {
        headers.Authorization = `Bearer ${token}`;
      }

      return headers;
    }

    static get(urlPath, params = {}, headers = {}) {
      const requestParams = {
        params,
        headers: { ...headers, ...this.headers(), },
      };
      return axios.get(this.formatUrl(urlPath), requestParams).then(
        (res) => res.data
      );
    }

    static post(urlPath, data = {}, headers = {}) {
      const requestParams = {
        headers: { ...headers, ...this.headers() },
      };

      return axios.post(this.formatUrl(urlPath), data, requestParams).then(
        (res) => res.data
      );
    }

    static put(urlPath, data = {}, headers = {}) {
      const requestParams = {
        headers: {
          ...headers,
          'Content-Type': 'application/json',
          ...this.headers()
        },
      };

      return axios.put(this.formatUrl(urlPath), data, requestParams).then(
        (res) => res.data
      );
    }

    static patch(urlPath, data = {}, headers = {}) {
      const requestParams = {
        headers: {
          ...headers,
          'Content-Type': 'application/json',
          ...this.headers()
        },
      };

      return axios.patch(this.formatUrl(urlPath), data, requestParams).then(
        (res) => res.data
      );
    }

    static delete(urlPath, params = {}, headers = {}) {
      const requestParams = {
        params,
        headers: {
          ...headers,
          'Content-Type': 'application/json',
          ...this.headers()
        },
      };

      return axios.delete(this.formatUrl(urlPath), requestParams).then(
        (res) => res.data
      );
    }

    static uploadFile(name, file, options = {}) {
      const data = new FormData();

      data.append(name, file);
      data.append('name', name);
      data.append('options', JSON.stringify(options));

      const requestParams = {
        headers: {
          ...this.headers(),
        },
      };

      return axios.post(
        this.formatUrl('attachment/upload'),
        data,
        requestParams,
      ).then(
        (res) => res.data
      );
    }
}

export default InternalApi;
