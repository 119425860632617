import { Grid } from "@mui/material";
import Loadable from "react-loadable";
import { Navigate } from "react-router-dom";
import { _url } from "../config/utils";

const Loading = (props) =>
    props.pastDelay ? (
        <Grid container height={"100vh"} justifyContent={"center"} alignItems={"center"}>
            <Grid>
                <img style={{ height: 100 }} alt="loading" src={_url("assets/images/loading-search-flight.gif")} />
            </Grid>
        </Grid>
    ) : null;
// export const RedesignHomepage = Loadable({ loader: () => import("./redesign/homepage/Homepage"), loading: Loading });
export const RedesignFlightSearchPage = Loadable({
    loader: () => import("./redesign/flightsearch/FlightSearch"),
    loading: Loading
});
export const PassengerDetail = Loadable({
    loader: () => import("./flightpassenger"),
    loading: Loading
});
export const ConfirmBooking = Loadable({
    loader: () => import("./redesign/confirmbooking/BookingConfirmation"),
    loading: Loading
});
export const ReviewPage = Loadable({
    loader: () => import("./review/ReviewPage"),
    loading: Loading
});
export const Refer = Loadable({ loader: () => import("./refer/index"), loading: Loading });
export const HowItWorks = Loadable({
    loader: () => import("./redesign/howitworks/HowItWorks"),
    loading: Loading
});
export const FourZeroFour = Loadable({ loader: () => import("./emptypage"), loading: Loading });
export const Bookings = Loadable({ loader: () => import("./booking/index"), loading: Loading });
export const BookingDetail = Loadable({
    loader: () => import("./bookingdetail/index"),
    loading: Loading
});
export const Login = Loadable({ loader: () => import("../components/Login"), loading: Loading });
export const BookingContract = Loadable({
    loader: () => import("../components/stays/checkout/StaysCheckoutView"),
    loading: Loading
});

export const RestrictedRoute = ({ auth, children }) => {
    if (auth.isAuthenticated) {
        return <Navigate to="/bookings" replace />;
    }

    return children;
};

export const PrivateRoute = ({ auth, children }) => {
    if (!auth.isAuthenticated) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login" replace />;
    }

    return children;
};
