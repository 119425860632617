import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ViewNavigation } from "../../booking-management/ViewNavigation";
import Coronavirus from "../../components/Coronavirus";
import PropertyDetailView from "../../components/stays/propertydetail/PropertyDetailView";
import HotelSearchView from "../../components/stays/result/HotelSearchView";
import {
    BookingContract,
    BookingDetail,
    Bookings,
    ConfirmBooking,
    HowItWorks,
    Login,
    PassengerDetail,
    PrivateRoute,
    RedesignFlightSearchPage,
    Refer,
    RestrictedRoute,
    ReviewPage
} from "../LoadingView";
import BookingManagement from "../bookingdetail/redesign/BookingManagement";
import Homepage from "../redesign/homepage/Homepage";
import SearchControls from "../redesign/homepage/SearchControls";
import AboutUs from "../redesign/aboutus/AboutUs";
import FinaliseAccount from "../redesign/finaliseaccount/FinaliseAccount";
import RedesignFooter from "../redesign/footer/Footer";
import Navbar from "../redesign/navbar/Navbar";
import Testimonial from "../testimonial/Testimonial";
import { getSiteValueByLang } from "../../utils/SiteUtils";
import { TrustpilotWidget } from "../../components/common/TrustpilotWidget";
import AppLayout from "./AppLayout";

const AppRoute = () => {
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const [viewModel, setViewModel] = useState({ status: "VIEW_NOT_LOADED", payload: {} });
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <AppLayout>
                        <Navbar showTransparentNavbar auth={auth} />
                        <Homepage />
                        <RedesignFooter />
                    </AppLayout>
                }
            />
            <Route
                path="/testimonial"
                element={
                    <AppLayout>
                        <Navbar auth={auth}>
                            <Testimonial />
                        </Navbar>
                    </AppLayout>
                }
            />
            <Route
                path="/aboutus"
                element={
                    <AppLayout>
                        <Navbar auth={auth}>
                            <AboutUs />
                        </Navbar>
                    </AppLayout>
                }
            />
            <Route
                path="/flightssearch"
                element={<SearchControls />}
            />
            <Route
                path="/flightssearch/s/:multicity"
                element={
                    <AppLayout>
                        <Navbar auth={auth}>
                            <RedesignFlightSearchPage />
                        </Navbar>
                    </AppLayout>
                }
            />
            <Route
                path="/flightssearch/s/:origin/:destination/:departureDate/:returnDate"
                element={
                    <AppLayout>
                        <Navbar auth={auth}>
                            <RedesignFlightSearchPage />
                        </Navbar>
                    </AppLayout>
                }
            />
            <Route
                path="/flightssearch/s/:origin/:destination/:departureDate"
                element={
                    <AppLayout>
                        <Navbar auth={auth}>
                            <RedesignFlightSearchPage />
                        </Navbar>
                    </AppLayout>
                }
            />
            <Route
                path="/flightssearch/passenger"
                element={
                    <AppLayout>
                        <Navbar auth={auth}>
                            <PassengerDetail />
                        </Navbar>
                    </AppLayout>
                }
            />
            <Route
                path="/flights/review"
                element={
                    <AppLayout>
                        <Navbar auth={auth}>
                            <ReviewPage />
                        </Navbar>
                    </AppLayout>
                }
            />
            <Route
                path="/stays/search"
                element={
                    <AppLayout>
                        <Navbar auth={auth}>
                            <HotelSearchView />
                        </Navbar>
                    </AppLayout>
                }
            />
            <Route
                path="/stays/detail"
                element={
                    <AppLayout>
                        <Navbar auth={auth}>
                            <PropertyDetailView />
                        </Navbar>
                    </AppLayout>
                }
            />
            <Route
                path="/stays/checkout"
                element={
                    <AppLayout>
                        <Navbar auth={auth}>
                            <BookingContract />
                        </Navbar>
                    </AppLayout>
                }
            />
            <Route path="/terms" element={<Redirect to={getSiteValueByLang("termsAndConditions")} />} />
            <Route path="/privacy-policy" element={<Redirect to={getSiteValueByLang("privacyPolicy")} />} />
            <Route
                path="/confirm"
                element={
                    <AppLayout>
                        <Navbar auth={auth}>
                            <ConfirmBooking />
                        </Navbar>
                    </AppLayout>
                }
            />
            <Route
                path="/finaliseaccount"
                element={
                    <AppLayout>
                        <Navbar auth={auth}>
                            <FinaliseAccount />
                        </Navbar>
                    </AppLayout>
                }
            />
            <Route
                path="/reviewtrustpilot"
                element={
                    <AppLayout>
                        <Navbar auth={auth}>
                            <TrustpilotWidget template="micro-star" style-width="100%" style-height="24px" />
                        </Navbar>
                    </AppLayout>
                }
            />
            <Route
                path="/confirmbooking"
                element={
                    <AppLayout>
                        <Navbar auth={auth}>
                            <ConfirmBooking />
                        </Navbar>
                    </AppLayout>
                }
            />
            <Route
                path="/how-it-works"
                element={
                    <AppLayout>
                        <Navbar auth={auth}>
                            <HowItWorks />
                        </Navbar>
                    </AppLayout>
                }
            />
            <Route
                path="/travel-alert/coronavirus"
                element={
                    <AppLayout>
                        <Navbar auth={auth}>
                            <Coronavirus />
                        </Navbar>
                    </AppLayout>
                }
            />
            <Route
                path="/login"
                element={
                    <AppLayout>
                        <RestrictedRoute auth={auth}>
                            <Navbar auth={auth}>
                                <Login />
                            </Navbar>
                        </RestrictedRoute>
                    </AppLayout>
                }
            />
            <Route
                path="/refer"
                element={
                    <AppLayout>
                        <PrivateRoute auth={auth}>
                            <Navbar auth={auth}>
                                <Refer />
                            </Navbar>
                        </PrivateRoute>
                    </AppLayout>
                }
            />
            <Route
                path="/bookings"
                element={
                    <AppLayout>
                        <PrivateRoute auth={auth}>
                            <Navbar auth={auth}>
                                <Bookings />
                            </Navbar>
                        </PrivateRoute>
                    </AppLayout>
                }
            />
            <Route
                path="/view/:path/*"
                element={
                    <AppLayout>
                        <PrivateRoute auth={auth}>
                            <Navbar auth={auth}>
                                <BookingManagement
                                    viewNavigation={ViewNavigation({
                                        navigate,
                                        targetHost: process.env.REACT_APP_BOOKING_MANAGEMENT_API_URL
                                    })}
                                />
                            </Navbar>
                        </PrivateRoute>
                    </AppLayout>
                }
            />
            <Route
                path="/bookings/:reference"
                element={
                    <AppLayout>
                        <PrivateRoute auth={auth}>
                            <Navbar auth={auth}>
                                <BookingDetail />
                            </Navbar>
                        </PrivateRoute>
                    </AppLayout>
                }
            />
            <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
    );
};

export default AppRoute;

const Redirect = ({ to }) => {
    window.location.href = to;
    return null;
};
