import { Box, Grid } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { Trans } from "react-i18next";
import { useGetWindowSize } from "../../../components/common/hook/WindowSize";
import { DisplayText, Text } from "../../../components/common/typography/Style";
import { ColorTheme } from "../../../styles/color";
import SearchForm from "../searchform/SearchForm";
import { HomeBackgroundImageContainer } from "./style";
import { TrustpilotWidget } from "../../../components/common/TrustpilotWidget";

const HomepageHeader = ({ selectedTab, selectedLocation, setSelectedLocation, setSelectedTab }) => {
    const { mobileView } = useGetWindowSize();

    return (
        <>
            <Helmet>
                <title>Pay Later Travel | Book Now, Pay Later</title>
            </Helmet>
            <HomeBackgroundImageContainer
                pt={["10px", "10px", "160px"]}
                bgimage={mobileView ? "assets/redesign/home_bg_mobile.png" : "assets/redesign/home_bg.png"}
            >
                <Box px={"16px"}>
                    <DisplayText
                        textAlign={"center"}
                        textsize={mobileView ? "28px" : undefined}
                        textcolor={ColorTheme.base.white}
                        size={"lg"}
                        textweight={"bold"}
                    >
                        <Trans>booknow</Trans>
                    </DisplayText>
                    <Text
                        size={mobileView ? "md" : "xl"}
                        textcolor={ColorTheme.base.white}
                        textAlign={"center"}
                        textweight={"regular"}
                    >
                        <Trans>spreadCost</Trans>
                    </Text>
                    {mobileView && (
                        <Box pt="15px" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <Box sx={{ position:"relative", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "350px", overflow: "hidden", left: "5px" }}>
                                <Box sx={{position: "relative", left: "-50px"}}>
                                    <TrustpilotWidget template={"micro-combo"} style-width={"1024px"} style-height={"28px"} theme="dark" />
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
                <Grid width={"100%"} container mt={mobileView ? "20px" : "40px"} justifyContent={"center"}>
                    <Grid item width={"100%"} maxWidth={"1140px"} pb={["0", "0", "30px"]}>
                        <SearchForm
                            selectedTab={selectedTab}
                            selectedLocation={selectedLocation}
                            setSelectedTab={setSelectedTab}
                            resetSelectedLocation={() => setSelectedLocation(null)}
                        />
                    </Grid>
                </Grid>
                {!mobileView && (
                    <Box pb="25px">
                        <TrustpilotWidget template={"horizontal"} style-width={"100%"} style-height={"28px"} theme="dark" />
                    </Box>
                )}
            </HomeBackgroundImageContainer>
        </>
    );
};

export default HomepageHeader;
